export const BREAKPOINTS = {
  PHONE: 600,
  PHONE_BIGGER: 768,
  PHONE_SMALL: 350,
  TABLET_PORTRAIT: 900,
  TABLET_LANDSCAPE: 1200,
  DESKTOP: 1800,
}

export const MESSAGES = {
  USER_CONTACT_NOT_FOUNT: 'Ops! Nenhum contato encontrado!',
  TRANSFER_POINTS_ONLY_FARMERS: 'As transferências podem ser feitas apenas entre agricultores!',
  TRANSFER_POINTS_ONLY_PARTICIPANTS_FARMERS: 'Apenas participantes produtores podem receber transferências de pontos.!',
  UNKNOW_ERROR: 'Não foi possível concluir sua solicitação. Tente novamente em alguns instantes.',
}

export const GUTTER = 20