import COLORS from "./colors";

const PRESETS = {

  // ------------- system ----------------------------
  // Custom presets will inherit default's definitions
  default: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.A,
    title: COLORS.DARK.A,
    text: COLORS.DARK.G,
    subtext: COLORS.DARK.E,
    link: COLORS.DARK.BG.B,
    inactive: COLORS.DARK.D,
    textdark: COLORS.DARK.BG.J,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonInactiveBackground: COLORS.DARK.D,
    buttonInactiveText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.LIGHT.BG.E,
    buttonSecondaryText: COLORS.DARK.A,
    label: COLORS.DARK.BG.B,
    success: COLORS.DARK.SUCCESS,
    warning: COLORS.DARK.WARNING,
    error: COLORS.DARK.ERROR,
    imageBackground: COLORS.MISC.IMAGE_BG,
  },
  defaultV2: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.A,
    title: COLORS.DARK.A,
    text: COLORS.DARK.G,
    subtext: COLORS.DARK.E,
    link: COLORS.DARK.H,
    inactive: COLORS.DARK.D,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonInactiveBackground: COLORS.DARK.D,
    buttonInactiveText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.LIGHT.BG.E,
    buttonSecondaryText: COLORS.DARK.F,
    label: COLORS.DARK.BG.B,
    success: COLORS.DARK.SUCCESS,
    warning: COLORS.DARK.WARNING,
    error: COLORS.DARK.ERROR,
    imageBackground: COLORS.MISC.IMAGE_BG,
  },
  // -------------------------------------------------

  colorful: {
    isBGLight: false,
    background: COLORS.DARK.BG.B,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.B,
    subtext: COLORS.LIGHT.D,
    link: COLORS.LIGHT.C,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.B,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.LIGHT.B,
    buttonInactiveBackground: COLORS.LIGHT.BG.C,
    buttonInactiveText: COLORS.DARK.C,
    label: COLORS.LIGHT.C,
  },
  colorfulV2: {
    isBGLight: false,
    background: COLORS.DARK.BG.G,
    title: COLORS.DARK.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.A,
    link: COLORS.DARK.BG.B,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.LIGHT.B,
    buttonInactiveBackground: COLORS.LIGHT.BG.C,
    buttonInactiveText: COLORS.DARK.C,
    label: COLORS.LIGHT.C,
  },
  box: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.C,
    title: COLORS.DARK.A,
    text: COLORS.DARK.G,
    subtext: COLORS.DARK.E,
    link: COLORS.DARK.B,
    inactive: COLORS.DARK.E,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.DARK.B,
    buttonInactiveBackground: COLORS.DARK.D,
    buttonInactiveText: COLORS.LIGHT.A,
  },
  boxV2: {
    isBGLight: false,
    background: COLORS.DARK.A,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.LIGHT.B,
    inactive: COLORS.LIGHT.D,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.DARK.B,
    buttonInactiveBackground: COLORS.DARK.D,
    buttonInactiveText: COLORS.LIGHT.A,
  },
  header: {
    isBGLight: false,
    background: COLORS.DARK.BG.D,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.DARK.F,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
  },
  form: {
    isBGLight: true,
    background: COLORS.LIGHT.BG.A,
    title: COLORS.DARK.A,
    text: COLORS.DARK.C,
    subtext: COLORS.DARK.D,
    link: COLORS.DARK.A,
    inactive: COLORS.DARK.D,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    label: COLORS.DARK.C,
  },
  listHeader: {
    isBGLight: false,
    background: COLORS.DARK.BG.E,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.LIGHT.A,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.A,
  },
  listBanner: {
    isBGLight: false,
    background: COLORS.DARK.BG.E,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    link: COLORS.LIGHT.B,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.LIGHT.BG.B,
    buttonText: COLORS.DARK.A,
  },
  funkyDefault: {
    isBGLight: false,
    background: COLORS.LIGHT.BG.D,
  },
  darker: {
    isBGLight: false,
    background: COLORS.DARK.BG.F,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.C,
    color: COLORS.LIGHT.A,
    link: COLORS.LIGHT.A,
  },
  greenBg: {
    isBGLight: false,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    link: COLORS.DARK.H,
    background: COLORS.DARK.H
  },
  greenBgV2: {
    isBGLight: false,
    background: COLORS.DARK.BG.I,
    title: COLORS.LIGHT.A,
    text: COLORS.LIGHT.A,
    subtext: COLORS.LIGHT.A,
    link: COLORS.LIGHT.A,
    inactive: COLORS.LIGHT.C,
    buttonBackground: COLORS.DARK.BG.B,
    buttonText: COLORS.LIGHT.A,
    buttonSecondaryBackground: COLORS.MISC.TRANSPARENT,
    buttonSecondaryText: COLORS.LIGHT.B,
    buttonInactiveBackground: COLORS.LIGHT.BG.C,
    buttonInactiveText: COLORS.DARK.C,
    label: COLORS.LIGHT.C,
  },
}

export default PRESETS;