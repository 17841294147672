const LAYOUT = {
  FONT_FAMILY: "'Branding', 'Branding SF'",
  FONT_SEMIBOLD: 'Branding Semibold',
  FONT_MONOSPACED: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  FONT_ICON: 'Font-Icon',
  FONT_BASE_SIZE: 'clamp(13px,calc(.8rem + .25vw),17px)',

  CONTAINER_SIZE: 1260,
  ARTICLE_SIZE: 888,
  FORM_SIZE: 600,
  GUTTER: 20,
  
  SMALL_BOX_RADIUS: '2px',
  BOX_RADIUS: '20px',
  LARGE_BOX_RADIUS: '8px',
  BUTTON_RADIUS: '50px',
  INPUT_RADIUS: '4px',
}

export default LAYOUT;