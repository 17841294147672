import styled from "@emotion/styled";
import { ChevronLeft, ChevronRight } from "react-feather";
import Slider from "react-slick";
import { BREAKPOINTS } from "../configs/consts";
import COLORS from "../../config/layout/colorsTemplates";

const SliderAtom = (props) => {
  const { config, children, sliderRef, customOptions } = props;

  const LeftArrow = (props) => {
    const { config, className, onClick } = props;
    let customClassName = className.includes('disabled') ? 'is-disabled' : '';

    return <StyledArrow config={config} onClick={onClick} className={'left ' + customClassName}>
      <ChevronLeft strokeWidth={3}/>
    </StyledArrow>
  }

  const RightArrow = (props) => {
    const { config, className, onClick } = props;
    let customClassName = className.includes('disabled') ? 'is-disabled' : '';

    return <StyledArrow config={config} onClick={onClick} className={'right ' + customClassName}>
      <ChevronRight strokeWidth={3}/>
    </StyledArrow>
  }

  const slickOptions = {...{
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <LeftArrow/>,
    nextArrow: <RightArrow/>,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },...customOptions}

  return <StyledSliderAtom>
    <Slider {...slickOptions} ref={sliderRef}>
      {children}
    </Slider>
  </StyledSliderAtom>
}

export default SliderAtom;

/* styled */

const StyledSliderAtom = styled.div`
  position: relative;

  .slick-slide{
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    & *{
      height: 100%;
    }

    &.slick-active{
      opacity: 1;
    }

    &:not(.slick-active){
      pointer-events: none;
      cursor: default;
    }
  }
`

const StyledArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: ${COLORS.mainGreenColorRGB};
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width:${BREAKPOINTS.PHONE_BIGGER}px) {
    display: none;
  }

  &.is-disabled{
    opacity: 0.5;
  }

  &.left{
    left: -20px;
  }

  &.right{
    right: -20px;
  }
`