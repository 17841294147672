const firstColorRGB               = '29,87,165';        // rgba(29,87,165)
const secondColorRGB              = '177,200,232';      // rgba(177,200,232)
const darkSecondColorRGB          = '0,45,116';         // rgb(0,45,116)
const darkColorRGB                = '60,60,60';         // rgb(60,60,60)
const modalBgRGB                  = '221,221,221';      // rgb(221,221,221)
const lightColorRGB               = '255,255,255';      // rgba(255,255,255)
const currentColorRGB             = '33,65,124';        // rgba(33,65,124)
const nextColorRGB                = '65,155,224';       // rgba(65,155,224)
const menuItemColorRGB            = '113,113,113';      // rgb(113,113,113)
const backgroundColorRGB          = '227,234,230';      // rgb(227,234,230)
const textDarkColorRGB            = '85,85,85';         // rgb(85,85,85)
const darkSecondColorVariationRGB = '141,141,141';      // rgb(141,141,141)
const badgeSubTitleRGB            = '178,178,178';      // rgb(178,178,178)
const pageTitleTextRGB            = '193,222,227';      // rgb(193,222,227)
const formButtonBackgroundRGB     = '29,87,165';        // rgba(29,87,165)
const textNegativeValueRGB        = '219,97,97';        // rgb(188,51,51)
const ReceiptValueRGB             = '236,240,243';      // rgb(188,51,51)
const secondGreenBackgroundRGB    = '171,196,50';       // rgb(171,196,50)
const linkBackgroundRGB           = '34,34,34';         // rgb(34,34,34)
const mainBlueColorRGB            = '7,89,234';         // rgb(7,89,234)
const mainGreenColorRGB           = '132,214,10';       // rgb(132,214,10)
const whiteColorRGB               = '255,255,255';      // rgba(255,255,255)
const blackColorRGB               = '0,0,0';            // rgba(0,0,0)
const barColorRGB                 = '240, 244, 247';    // rgba(240, 244, 247)
const grayColorC1                 = '193, 193, 193';    // rgba(193, 193, 193)

const COLORS = {
  firstColor: 'rgb(' + firstColorRGB + ')',
  firstRGBColor: firstColorRGB,
  darkFirstRGBColor: blackColorRGB,
  textDarkColor: 'rgb(' + textDarkColorRGB + ')',
  textWhiteColor: 'rgb(' + lightColorRGB + ')',
  secondColor: 'rgb(' + secondColorRGB + ')',
  darkSecondColor: 'rgb(' + darkSecondColorRGB + ')',
  darkSecondColorRGB: darkSecondColorRGB,
  darkSecondColorVariation: 'rgb(' + darkSecondColorVariationRGB + ')',
  modalBgColor: 'rgb(' + modalBgRGB + ')',
  modalBgColorRGB: modalBgRGB,
  modalTabBorderColorRGB: blackColorRGB,
  modalTextColor: 'rgb(' + firstColorRGB + ')',
  modalTextColorRGB: blackColorRGB,
  badgeTitle: 'rgb(' + darkColorRGB + ')',
  badgeSubTitle: 'rgb(' + badgeSubTitleRGB + ')',
  negativeColor: 'rgba(' + lightColorRGB + ',.99)',
  negativeRGBColor: lightColorRGB,
  safeAreaColor: 'rgb(' + lightColorRGB + ')',
  menuItemColor: 'rgb(' + menuItemColorRGB + ')',
  backgroundColor: 'rgb(' + backgroundColorRGB + ')',
  cardbgColor: 'rgb(' + lightColorRGB + ')',
  pageTitleTextColor: 'rgb(' + pageTitleTextRGB + ')',
  formButtonBackgroundColor: 'rgb(' + formButtonBackgroundRGB + ')',
  textNegativeValue: 'rgb(' + textNegativeValueRGB + ')',
  ReceiptValueRGB: 'rgb(' + ReceiptValueRGB + ')',
  secondGreenBackgroundRGB: 'rgb(' + secondGreenBackgroundRGB + ')',
  currentColorRGB: 'rgb(' + currentColorRGB + ')',
  nextColorRGB: 'rgb(' + nextColorRGB + ')',
  linkBackgroundRGB: 'rgb(' + linkBackgroundRGB + ')',
  mainBlueColorRGB: 'rgb(' + mainBlueColorRGB + ')',
  mainGreenColorRGB: 'rgb(' + mainGreenColorRGB + ')',
  whiteColorRGB: 'rgb(' + whiteColorRGB + ')',
  blackColorRGB: 'rgb(' + blackColorRGB + ')',
  whiteFirstColor: 'rgba(' + whiteColorRGB + ',.5)',
  darkFirstColor: 'rgba(' + blackColorRGB + ',.5)',
  barColorRGB: 'rgb(' + barColorRGB + ')',
  grayColorC1: 'rgb(' + grayColorC1 + ')'
};

export default COLORS;