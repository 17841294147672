import { NavLink } from "react-router-dom";
import { useWindowSize } from "../utils/useWindowSize";
import { BREAKPOINTS } from "../configs/consts";

import Campaigns from "../Campaigns";
import MGM from "../MGM";

const CoreLink = props => {
  let { to, url, target, children, exact, className, routeO, label, domain = null, state = {} } = props;

  const { width: widthWindow } = useWindowSize();
  const isMobile = widthWindow <= BREAKPOINTS.PHONE;
  const listFilterPerfilHideInvoice = ['Ciacerradokam', 'ciacerradokam'];

  const openCampaigns = () => {
    props.rightPanel.setMaxWidth(576);
    props.rightPanel.setContent(() =>
      <Campaigns
        {...props}
      />
    );
    props.rightPanel.setIsOpen(true);
  }

  const openMGM = () => {
    props.rightPanel.setMaxWidth(420);
    props.rightPanel.setContent(() =>
      <MGM
        {...props}
      />
    );
    props.rightPanel.setIsOpen(true);
  }

  // Alias
  if(url && !to){
    to = url;
  }

  // Alias
  if(label && !children){
    if(label === 'Notas Fiscais' && listFilterPerfilHideInvoice.includes(global.userType)) {
      children = '';
    } else {
      children = label;
    }
  }

  if (label === "Transfira pontos") {
    state.openTransferModal = true;
  }

  if(routeO){
    // Se foi definido permissão
    if(
      (routeO.allowedTypes && (!global.userTypes || !routeO.allowedTypes.filter(value => global.userTypes.includes(value)).length)) ||
      (routeO.notAllowedTypes && (!global.userTypes || routeO.notAllowedTypes.filter(value => global.userTypes.includes(value)).length))
    ){
      return null;
    }
    
    to = routeO.path || '';
  }

  if(to?.includes('/meu-cadastro') && isMobile){
    to = '/minha-conta'
  }

  const handleGTM = () => {
    if (children === 'Campanhas') {
      openCampaigns()
    }

    if (children === 'Indique um amigo') {
      openMGM()
    }

    if (domain !== 'syngenta-dev-c4409.firebaseapp.com' && children === 'Catálogo') {
      /* Google tag manager */
      // slide 15
      window.dataLayer.push({
        'event': 'click',
        'event_name': 'click',
        'web': {
        'url': document.URL,
        'hostname': document.location.hostname,
        'title': document.title
        },
        'debug': {
        'dl_version': 1,
        'site': 'sign_up'
        },
        'ep': {
        'journey': 'spend_virtual_currency',
        'button_name': 'ecommerce',
        'step': '4'
        }
      })
    }
  }

  return <NavLink 
      exact={exact} 
      to={{
        pathname: to,
        state,
      }}
      target={target} 
      className={className} 
      onClick={handleGTM}
    >
      {children}
    </NavLink>
}

export default CoreLink;