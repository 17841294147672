import styled from "@emotion/styled";
import { useMemo } from "react";
import ToolHelper from "./ToolHelper";
import Button from "../Atoms/Button";
import { BREAKPOINTS } from "../configs/consts";
import COLORS from "../../config/layout/colorsTemplates";

const WebPopup = (props) => {
  const config = useMemo(() => ToolHelper.getThemedConfig(props,'ProductPopup.preset'),[props.config]);

  const profiles = props?.user?.acg_LoyaltyProfiles__c.split(';') || [];

  const goTo = () => {
    props?.history.push('/meu-cadastro');
    props.popup.setIsOpen(false);
  }

  return <StyledWebPopup config={config} COLORS={COLORS}>
    <div className={'white-box'}>
      <div className={'internal-container'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            justifyContent: 'flex-start',
            lineHeight: '16px'
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
            <div className='text-category'>Olá!</div>
          </div>

          <p className='text-title'>
            {profiles?.includes('Focal Point') ? (
              <p>Complete os novos dados obrigatórios do seu cadastro pra você conseguir continuar com suas ações no Acessa.</p>
            ): <p>Este Canal possui consultores com dados incompletos, entre em contato com o Focal Point.</p> }
          </p>
        </div>

        <div className='buttons'>
          {profiles?.includes('Focal Point') ? (
            <Button onClick={goTo} className='btn-complete' backgroundColor={COLORS.mainGreenColorRGB}>Completar o cadastro</Button>
          ):null}
        </div>    
      </div>
    </div>
  </StyledWebPopup>
}

export default WebPopup;

const StyledWebPopup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  max-width: 740px;

  .close-button {
    display: none;
  }
  
  .text-category {
    font-weight: bold;
    font-size: 1.4em;
    color: ${(e) => e?.config?.colors?.DARK?.A};
  }

  .text-title {
    font-size: 1.3em;
    color: ${(e) => e?.config?.colors?.DARK?.E};
    line-height: 22px;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    .text-category {
      font-weight: bold;
      font-size: 1em;
      color: #D3D3D3;
    }
  
    .text-title {
      font-weight: bold;
      font-size: 1.2em;
      color: #000000;
    }
  }
 
  .white-box {
    border-radius: 5px;

    .buttons {
      display: flex;
      justify-content: center;
    }

    .btn-complete {
      background: COL;
      color: #FFF; 
      font-size: 16px;
      margin-top: 0;
      border-radius: 50px;
      min-width: 50%;      
    }

    @media (max-width: ${BREAKPOINTS.PHONE}px) {
      padding: 0px;
      background-color: #FFF;

      .btn-complete {
        color: #FFF; 
        font-size: 16px;
        margin-top: 0;
      }
    }
   
    .internal-container {
      margin: 30px 40px;
      display: flex;
      flex-direction: column;
      gap: 22px;

      .title {
        font-weight: bold;
        font-size: 1.5em;
      }

      @media (max-width: ${BREAKPOINTS.PHONE}px) {
        padding: 0px;
        gap: 12px;

        .buttons {
          display: flex;
          flex-direction: column;
        }
  
      }
    }
  }
`;
