import React, {createContext, useState} from 'react';
import ApiService from '../services/ApiService';

const Context = createContext();

export const Provider = ({ children }) => {
  const apiService = new ApiService("");
  const [responseAcessCode, setResponseAcessCode] = useState({});
  const [userInfo, setUserInfo] = useState({});

  return (
    <Context.Provider value={{ apiService, setResponseAcessCode, responseAcessCode, userInfo, setUserInfo }}>
      {children}
    </Context.Provider>
  );
};

export default Context;