import styled from "@emotion/styled";
import { useRef } from 'react';

import HamIcon from '../Molecules/HamIcon.js';
import HeaderDropMenu from './HeaderDropMenu';
import { BREAKPOINTS } from '../configs/consts'

function HeaderMobile(props) {
  const thisRef = useRef(null);
  const { toggleIsOpen, isOpen, headerConfig, config, isFarmer, openNotifications, rightPanel, data, dataHelper, openCampaigns } = props
  

  return <StyledHeaderMobile ref={thisRef} config={config} isOpen={isOpen} onClick={() => toggleIsOpen()}>
    <HamIcon size={21} className={isOpen ? 'open' : ''} color={config?.themeLayout?.link} />
    <HeaderDropMenu openNotifications={openNotifications} openCampaigns={openCampaigns} isFarmer={isFarmer} parentRef={thisRef} toggleIsOpen={toggleIsOpen} isOpen={isOpen} menuItems={headerConfig?.links} mobileItems={headerConfig?.headerUser?.menuItems} config={config} rightPanel={rightPanel} data={data} dataHelper={dataHelper} message={props.message} />
  </StyledHeaderMobile>
}

export default HeaderMobile;

/* style */

const StyledHeaderMobile = styled.div`
  overflow: hidden;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  // background: ${props => props.isOpen ? 'rgba(0,0,0,0.1)' : 'none'};
  
  @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    display:none;
  }
`